import styled from '@emotion/styled';

const Description = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  borderRadius: '0.3rem',
  height: '1.4rem',
  marginTop: '1rem',
  marginLeft: '5rem',
  width: 'calc(100% - 10rem)',
}));

export default Description;
