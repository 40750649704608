import styled from '@emotion/styled';

const HeaderText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2rem',
  width: `calc(100% - 5rem)`,
});

export default HeaderText;
