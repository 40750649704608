import styled from '@emotion/styled';

const Header = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  marginBottom: '0.5rem',
  width: '100%',
});

export default Header;
