import styled from '@emotion/styled';

const Button = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  borderRadius: '99.9rem',
  height: '1.6rem',
  marginRight: '1rem',
  width: '8rem',
}));

export default Button;
