import styled from '@emotion/styled';

const EmptyHeader = styled('div')(({ theme }) => ({
  color: theme.colors.gray['600'],
  fontSize: theme.fonts.size['20'],
  fontWeight: theme.fonts.weight.bold,
  lineHeight: theme.fonts.lineHeight['26'],
  marginTop: '1.2rem',
}));

export default EmptyHeader;
