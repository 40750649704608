import EmptyHeader from './primitives/EmptyHeader';
import EmptyIcon from './primitives/EmptyIcon';
import EmptySubtitle from './primitives/EmptySubtitle';
import EmptyWrapper from './primitives/EmptyWrapper';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { empty } from 'constants/assets';

const Empty = () => {
  const translate = useTranslate();

  return (
    <EmptyWrapper>
      <EmptyIcon src={empty} />
      <EmptyHeader>
        {translate("These episodes aren't here yet...")}
      </EmptyHeader>
      <EmptySubtitle>
        {translate("Follow this podcast to be notified when they're ready")}
      </EmptySubtitle>
    </EmptyWrapper>
  );
};

export default Empty;
