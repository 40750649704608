import ShouldShow from 'components/ShouldShow';
import {
  Button,
  ButtonsContainer,
  Card,
  Description,
  Header,
  HeaderText,
  PlayButton,
  Subtitle,
  Title,
} from './primitives';

type Props = {
  isExpanded?: boolean;
};

function DummyCard({ isExpanded = false }: Props) {
  return (
    <Card>
      <Header>
        <PlayButton />
        <HeaderText>
          <Title />
          <Subtitle />
        </HeaderText>
      </Header>
      <ShouldShow shouldShow={isExpanded}>
        <Description />
        <Description />
        <Description />
        <Description />
        <Description />
        <ButtonsContainer>
          <Button />
          <Button />
        </ButtonsContainer>
      </ShouldShow>
    </Card>
  );
}

export default DummyCard;
