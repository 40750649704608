import styled from '@emotion/styled';

const Subtitle = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  borderRadius: '0.3rem',
  height: '1.6rem',
  marginTop: '1rem',
  width: '40%',
}));

export default Subtitle;
