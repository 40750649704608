import styled from '@emotion/styled';

const EmptySubtitle = styled('div')(({ theme }) => ({
  color: theme.colors.gray['400'],
  fontSize: theme.fonts.size['14'],
  lineHeight: '2rem',
  marginBottom: '8rem',
  marginTop: '.8rem',
}));

export default EmptySubtitle;
