import styled from '@emotion/styled';

const ButtonsContainer = styled('div')({
  display: 'flex',
  marginLeft: '5rem',
  marginTop: '1.5rem',
  width: 'calc(100% - 5rem)',
});

export default ButtonsContainer;
