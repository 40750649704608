import styled from '@emotion/styled';

const PlayButton = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  borderRadius: '50%',
  height: '3rem',
  width: '3rem',
}));

export default PlayButton;
